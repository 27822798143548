<template>
  <aside>
    <header>
      <h2>Select a Banner</h2>
      <button class="close" @click="close"><span class="sr-only">Close modal window</span></button>
    </header>

    <main>
      <ul class="banners">
        <li v-for="(banner, index) in banners" :key="`banner_${index}`">
          <a href="#" @click.prevent="selectBanner(banner)">
            <img :src="`https://img-cdn.hithrive.com/teams-award-skins/${banner.award_card_id}.png`" height="100" />
          </a>
        </li>
      </ul>
    </main>
  </aside>
</template>

<script>
export default {
  computed: {
    banners() {
      const filter_params = {
        type: 'AWARD_SKIN'
      };

      return _.filter( this.icons, filter_params );
    }
  },
  data() {
    return {
      icons: null
    }
  },
  created() {
    this.populateIcons()
  },
  methods: {
    close() {
      this.$emit('done')
    },
    async populateIcons() {
      const resp = await this.$api.Team.get_icons()
      this.icons = resp;
    },
    selectBanner(banner) {
      this.$emit('done', 'BANNER_SELECTED', banner)
    }
  }
}
</script>

<style lang="scss" scoped>
.banners {
  padding: 0;
  margin: 0;
  display: block;

  > li {
    display: flex;

    +li {
      margin-top: 15px;
    }

    > a {
      display: block;
      border-radius: 6px;
      overflow: hidden;
      height: 100px;

      &:hover {
        box-shadow: $primary 0 0 0 1px, rgba($primary,0.3) 0 0 0 3px;
      }

      > img {
        display: block;
      }
    }
  }
}
</style>